import platform from "platform";
import compareVersions from "compare-versions";

interface ToStringable {
  toString(): string;
}

const greaterOrEqual = (a: ToStringable | undefined, b: ToStringable) =>
  a !== undefined && compareVersions(a.toString(), b.toString()) >= 0;

declare global {
  interface Window {
    // different browsers define different things on window. These are all
    // optional because they vary by runtime!
    opr?: { addons: unknown };
    opera?: unknown;

    // our stuff
    isSupportedBrowser(versions: {
      chrome: string;
      safari: string;
      firefox: string;
      edge: string;
    }): boolean;
    isUnsupportedBrowser: boolean;
  }
}

// See https://github.com/NoRedInk/wiki/blob/master/Platform-Support.md
// See config/initializers/constants.rb
// See ApplicationHelper#browser_incompatible?
window.isSupportedBrowser = function ({
  chrome,
  safari,
  firefox,
  edge,
}): boolean {
  if (isUnsupportedBrowser) {
    return false;
  }

  switch (platform.name) {
    case "Chrome":
      return greaterOrEqual(platform.version, chrome);
    case "Chrome Mobile":
      return greaterOrEqual(platform.version, chrome);
    case "Safari":
      return greaterOrEqual(platform.version, safari);
    case "Firefox":
      return greaterOrEqual(platform.version, firefox);
    case "Firefox Mobile":
      return greaterOrEqual(platform.version, firefox);
    case "Firefox for iOS":
      return greaterOrEqual(platform.version, firefox);
    case "Microsoft Edge":
      return greaterOrEqual(platform.version, edge);
    case "IE":
      return false;
    case "IE Mobile":
      return false;
    default:
      return false;
  }
};

const isUnsupportedBrowser = (function (): boolean {
  // Opera 8.0+
  const isOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0;
  // Firefox 1.0+
  // const isFirefox = typeof InstallTrigger !== "undefined";
  // At least Safari 3+: "[object HTMLElementConstructor]"
  // const isSafari =
  //   Object.prototype.toString.call(window.HTMLElement).indexOf("Constructor") >
  //   0;
  // true if IE < 9
  const isOldIE = typeof console === "undefined";
  // Internet Explorer 6-11
  // const isIE = false || !!document.documentMode;
  // Edge 20+
  // const isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1+
  // const isChrome = !!window.chrome && !!window.chrome.webstore;
  // Blink engine detection
  // const isBlink = (isChrome || isOpera) && !!window.CSS;
  return isOpera || isOldIE;
})();
